import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'mobx-react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import AppStore from './store';
import colors from 'tailwindcss/colors';
import NavBar from './NavBar';
import Search from './Search';
import Dashboard from './Dashboard';
import PlanPage from './PlanPage';
import PlansPage from './PlansPage';
import TaskListPage from './TaskListPage';
import TaskPage from './TaskPage';
import ReportPage from './ReportPage';
import AiChainPage from './AiChainPage';
import Chat from './Core/Chat';
import LoginPage from './Login/LoginPage';
import Profile from './Profile/';
import LoginSuccess from './Login/Success';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.scss';

if (!window.store) {
  console.log('Creating new store');
  window.store = new AppStore();
}

const App = observer(() => {
  const redirect = window.store.redirect;
  const isLoggedIn = window.store.isLoggedIn;

  return (
    <ThemeProvider theme={colors}>
      <Provider store={window.store}>
        <Router>
          {redirect ? <Redirect to={redirect} /> : null}
          {isLoggedIn ? (
            <>
              {/* Logged in */}
              {window.store.profile.status ? (
                <>
                  {/* Logged in with plan */}
                  <Switch>
                    <Route component={NavBar} />
                  </Switch>
                  <Switch>
                    <Route path="/plans" exact component={PlansPage} />
                    <Route path="/plan/:planId" exact component={PlanPage} />
                    <Route path="/task/:taskId" exact component={TaskPage} />
                    <Route path="/report/:taskId" exact component={ReportPage} />
                    <Route path="/search" exact component={Search} />
                    <Route path="/tasklist" exact component={TaskListPage} />
                    <Route path="/ai/:business/:page/:id" component={TaskPage} />
                    <Route path="/aichain/:aichainId" component={AiChainPage} />
                    <Route path="/ai/code/debugging" component={Chat} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/signup/failed" component={Profile} />
                    <Route path="/signup/success" component={LoginSuccess} />
                    <Route path="/" exact component={PlanPage} />
                  </Switch>
                </>
              ) : (
                <>
                  {/* Logged in but no plan */}
                  {/* Render your desired component or redirect */}
                </>
              )}
            </>
          ) : (
            <>
              {/* Not logged in */}
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/login" />
                </Route>
                <Route path="/" component={LoginPage} />
              </Switch>
            </>
          )}
        </Router>
      </Provider>
    </ThemeProvider>
  );
});

export default App;
