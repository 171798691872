import React, { useState } from "react";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import TextareaAutosize from "react-textarea-autosize";
import { CheckIcon } from "@heroicons/react/outline";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUpload from "./FileUpload";
import EntryDropdown from "./EntryDropdown";
import CodeEditor from "@uiw/react-textarea-code-editor";

const EntryInput = inject("store")(
  observer(({ store, prompt, Icon, size, task, language, index }) => {
    const [currentValue, setCurrentValue] = useState(prompt.value || "");

    const onChange = (e) => {
      setCurrentValue(e.target.value);
      prompt.value = e.target.value;
      prompt.error = "";
    };

    const handleQuillChange = (htmlContent, delta, source, editor) => {
      // Replace <br> with \n in the content
      const textContent = htmlContent.replace(/<br>/g, "\n");
      setCurrentValue(textContent);
      prompt.value = textContent;
      prompt.error = "";
    };

    const onChangeDropdown = (e) => {
      setCurrentValue(e.value);
      prompt.value = e.value;
      prompt.error = "";
    };

    const isMinLength = computed(() => {
      if (!prompt.min) {
        return false;
      }
      if (!prompt.type === "number") {
        return false;
      }
      if (prompt.value.length === 0) {
        return false;
      }
      if (prompt.value.length < prompt.min) {
        return true;
      }
      return false;
    });

    const isLongEnoughToDrop = computed(() => prompt.value.length < 30);

    const currentNumberOfCharacters = computed(() => {
      let currentCharacters = prompt.value.length;
      let maxCharacters = 80;
      if (prompt.type === "textarea") {
        maxCharacters = 400;
      }
      if (prompt.maxLength) {
        maxCharacters = prompt.maxLength;
      }
      if (prompt.max) {
        maxCharacters = prompt.max;
      }

      return `${currentCharacters}/${maxCharacters}`;
    });

    return (
      <div className={`${size ? "mt-4" : "mt-4"}`}>
        {prompt.title && (
          <label
            htmlFor={prompt.attr}
            className="text-gray-600 font-medium text-md"
          >
            {prompt.title}
          </label>
        )}

        <div className="relative flex flex-col">
          {prompt.options ? null : (
            <div
              className={`text-xs absolute z-30 right-2 select-none pointer-events-none transition-all top bg-white px-2 ${
                isMinLength ? "text-red-500" : "text-gray-400"
              } ${!isLongEnoughToDrop && size ? "" : ""}`}
            >
              {currentNumberOfCharacters} chars
            </div>
          )}

          <label
            htmlFor={prompt.attr}
            className="relative transition text-gray-600 focus-within:text-gray-800 block"
          >
            {Icon ? (
              <Icon
                className={`
					${size ? "" : "h-6 w-6 top-5 left-2"} 
					${size === "sm" && "h-4 w-4 top-5 left-2"} 
					${size === "lg" && "h-8 w-8 top-5 left-2"} 
					transition absolute  select-none pointer-events-none opacity-75`}
              />
            ) : null}
            {prompt.type ? null : (
              <input
                name={prompt.attr}
                id={prompt.attr}
                maxLength={prompt.maxLength || 80}
                {...prompt}
                autoFocus={index === 0}
                className={`outline-none focus:outline-none text-${
                  size || "lg"
                } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400 ${
                  prompt.error ? "border-red-400" : "border-gray-300"
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
						${size ? "" : "pl-9"}
						${size === "sm" && "pl-7"}
						${size === "lg" && "pl-12"}
						`
                } `}
                onChange={onChange}
              />
            )}

            {prompt.type === "text" && (
              <input
                name={prompt.attr}
                id={prompt.attr}
                maxLength={prompt.maxLength || 400}
                {...prompt}
                autoFocus={index === 0}
                className={`outline-none focus:outline-none text-${
                  size || "lg"
                } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${
                  prompt.error ? "border-red-400" : "border-gray-300"
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
						${size ? "" : "pl-7"}
						${size === "sm" && "pl-7"}
						${size === "lg" && "pl-12"}
						`
                }`}
                onChange={onChange}
              />
            )}
          </label>

          {prompt.type === "dropdown" && (
            <EntryDropdown
              onChange={onChangeDropdown}
              options={prompt.options}
            />
          )}

          {prompt.type === "code" && (
            <div className="mt-4 -mx-6" style={{ backgroundColor: "#f5f5f5" }}>
              <CodeEditor
                style={{
                  fontFamily: "JetBrains Mono",
                  fontSize: "1rem",
                }}
                padding={30}
                language={language}
                onChange={onChange}
                autoFocus={index === 0}
                name={prompt.attr}
                id={prompt.attr}
                {...prompt}
              />
            </div>
          )}

          {prompt.type === "textarea" && (
            <div className={`${size ? "mt-4" : "mt-4"}`}>
              <ReactQuill
                value={currentValue.replace(/\n/g, "<br>")}
                onChange={handleQuillChange}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    ["link"],
                    [{ linebreak: "\n" }],
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "size",
                  "list",
                  "bold",
                  "italic",
                  "underline",
                  "link",
                  "linebreak",
                ]}
              />
            </div>
          )}

          {/* Rest of the code */}
        </div>

        {(prompt.label || prompt.error) && (
          <div
            htmlFor={prompt.attr}
            className={`${
              prompt.error ? "text-red-400" : "text-gray-400"
            } text-xs transition-all line mt-1`}
          >
            {prompt.error || prompt.label}
          </div>
        )}
      </div>
    );
  })
);

export default EntryInput;
