import React, { useEffect, useState, useCallback } from "react";

import MainBody from "./Components/Body";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer, inject } from "mobx-react";
import AiChainFlow from "./Components/AiChain/AiChainFlow";

const AiChainPage = inject("store")(
  observer(({ store }) => {
    let { aichainId } = useParams();
    const [editedTitle, setEditedTitle] = useState("");

    useEffect(() => {
      // Fetch task here

      console.log("aichainId: ", aichainId);
      store.fetchAiChain(aichainId);
    }, [aichainId, store]);

    // Function to handle title editing
    const handleTitleEdit = () => {
      if (editedTitle.trim() !== "") {
        // Update the title in the store
        store.aichain.title = editedTitle;
      }
    };

    // Function to convert an object to a JSON string with indentation
    const formatJSON = (data) => {
      return JSON.stringify(data, null, 2);
    };

    return (
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        {store.aichain && (
          <>
            <Helmet>
              <title>{`${store.aichain.title} Task`}</title>
            </Helmet>

            <div className="my-4">
              <h2 className="text-2xl font-bold">Data</h2>
              <div className="border border-gray-300 p-4 rounded-lg mt-2">
                <p className="text-gray-700">
                  <strong>Title: </strong>
                  {store.aichain.title}
                </p>
                <p className="text-gray-700">
                  <strong>aiModule: </strong>
                  {store.aichain.aiModule}
                </p>
                <p className="text-gray-700">
                  <strong>api: </strong>
                  {store.aichain.api}
                </p>
                <p className="text-gray-700">
                  <strong>user: </strong>
                  <div className="border border-gray-300 p-4 rounded-lg mt-2">
                    <p className="text-gray-700">
                      <strong>First Name: </strong>
                      {store.aichain.user.fname}
                    </p>
                    <p className="text-gray-700">
                      <strong>Last Name: </strong>
                      {store.aichain.user.lname}
                    </p>
                    <p className="text-gray-700">
                      <strong>User Id: </strong>
                      {store.aichain.user._id}
                    </p>
                  </div>
                </p>
                <p className="text-gray-700">
                  <strong>_id: </strong>
                  {store.aichain._id}
                </p>
              </div>
            </div>

            <div className="my-4">
              <h2 className="text-2xl font-bold">Prompts</h2>
              {store.aichain.aiPrompts.map((aiPrompt, index) => (
                <div
                  key={index}
                  className="border border-gray-300 p-4 rounded-lg mt-2"
                >
                  <p className="text-gray-700">
                    <strong>Prompt Type: </strong>
                    {aiPrompt.promptType}
                  </p>
                  <p className="text-gray-700">
                    <strong>Action Type: </strong>
                    {aiPrompt.action.actionType}
                  </p>
                  <p className="text-gray-700">
                    <strong>Action Details: </strong>
                    {aiPrompt.action.actionDetails}
                  </p>
                  <p className="text-gray-700">
                    <strong>LLM Model: </strong>
                    {aiPrompt.llm.hyperparameters.model}
                  </p>
                  <p className="text-gray-700">
                    <strong>LLM Prompt Template: </strong>
                    {aiPrompt.llm.promptTemplate}
                  </p>
                  <div className="my-2">
                    <strong>Prompt Template Inputs: </strong>
                    {aiPrompt.llm.promptTemplateInputs.map(
                      (input, inputIndex) => (
                        <div
                          key={inputIndex}
                          className="border border-gray-300 p-2 rounded-lg mt-2"
                        >
                          <p className="text-gray-700">
                            <strong>_id: </strong>
                            {input._id}
                          </p>
                          <p className="text-gray-700">
                            <strong>Input Type: </strong>
                            {input.inputType}
                          </p>

                          <p className="text-gray-700">
                            <strong>Task: </strong>

                            <div className="border border-gray-300 p-2 rounded-lg mt-2">
                              <p className="text-gray-700">
                                <strong>Task ID: </strong>{" "}
                                {input.taskWithInputIdOrAiOutputId._id}
                              </p>
                              <p className="text-gray-700">
                                <strong>Task Title: </strong>{" "}
                                {input.taskWithInputIdOrAiOutputId.title}
                              </p>
                              <p className="text-gray-700">
                                <strong>Task Description: </strong>{" "}
                                {input.taskWithInputIdOrAiOutputId.description}
                              </p>
                            </div>
                          </p>
                          {/* <p className="text-gray-700">
                            <strong>
                              Specific Input or Output ID or Prompt ID:{" "}
                            </strong>
                            {input.specificInputOrOutputIdOrPromptId}
                          </p> */}

                          {input.specificInputOrOutputIdOrPromptIdData &&
                            (input.inputType == "uiInput" ||
                              input.inputType == "uiOutput") && (
                              <>
                                <strong>Input Data Details:</strong>

                                <div className="border border-gray-300 p-2 rounded-lg mt-2">
                                  <p>
                                    <strong>Title:</strong>{" "}
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .title
                                    }
                                  </p>
                                  <p>
                                    <strong>Description: </strong>
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .desc
                                    }
                                  </p>
                                  <p>
                                    <strong>Type: </strong>
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .type
                                    }
                                  </p>
                                  <p>
                                    <strong>id: </strong>
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        ._id
                                    }
                                  </p>
                                  <p>
                                    <strong>Value:</strong>{" "}
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .value
                                    }
                                  </p>
                                  {/* Render other Input Data Details fields as needed */}
                                </div>
                              </>
                            )}

                          {input.specificInputOrOutputIdOrPromptIdData &&
                            input.inputType == "aiOutput" && (
                              <>
                                <strong>Input Data Details:</strong>

                                <div className="border border-gray-300 p-2 rounded-lg mt-2">
                                  <p>
                                    <strong>Ai Response:</strong>{" "}
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .airesponse
                                    }
                                  </p>
                                </div>
                              </>
                            )}

                          {input.specificInputOrOutputIdOrPromptIdData &&
                            input.inputType == "promptOutput" && (
                              <>
                                <strong>Input Data Details:</strong>

                                <div className="border border-gray-300 p-2 rounded-lg mt-2">
                                  <p>
                                    <strong>Prompt Output:</strong>{" "}
                                    {
                                      input
                                        .specificInputOrOutputIdOrPromptIdData
                                        .output
                                    }
                                  </p>
                                </div>
                              </>
                            )}

                          <p className="text-gray-700">
                            <strong>Key: </strong>
                            {input.key}
                          </p>
                          <p className="text-gray-700">
                            <strong>Value: </strong>
                            {input.value}
                          </p>
                        </div>
                      )
                    )}
                  </div>

                  <div className="my-2">
                    <strong>Hyperparameters: </strong>
                    <p className="text-gray-700">
                      <strong>Model: </strong>
                      {aiPrompt.llm.hyperparameters.model}
                    </p>
                    <p className="text-gray-700">
                      <strong>Temperature: </strong>
                      {aiPrompt.llm.hyperparameters.temperature}
                    </p>
                    <p className="text-gray-700">
                      <strong>Max Tokens: </strong>
                      {aiPrompt.llm.hyperparameters.max_tokens}
                    </p>
                    {/* Add more hyperparameter fields as needed */}
                  </div>

                  <div className="my-2">
                    <strong>Prompt Outputs: </strong>
                    {aiPrompt.promptOutput.map((output, outputIndex) => (
                      <div
                        key={outputIndex}
                        className="border border-gray-300 p-2 rounded-lg mt-2"
                      >
                        <p className="text-gray-700">
                          <strong>Type: </strong>
                          {output.type}
                        </p>
                        <p className="text-gray-700">
                          <strong>Input: </strong>
                          {output.input}
                        </p>
                        <p className="text-gray-700">
                          <strong>Output: </strong>
                          {output.output}
                        </p>
                        <p className="text-gray-700">
                          <strong>Credits: </strong>
                          {output.credits}
                        </p>
                        <p className="text-gray-700">
                          <strong>Created: </strong>
                          {output.created}
                        </p>
                        <p className="text-gray-700">
                          <strong>_id: </strong>
                          {output._id}
                        </p>
                        <p className="text-gray-700">
                          <strong>Price: </strong>
                          {output.price}
                        </p>
                        <p className="text-gray-700">
                          <strong>AI Prompt ID: </strong>
                          {output.aiPromptId}
                        </p>
                        {/* Add more output fields as needed */}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="my-4">
              <h1 className="text-2xl font-bold">AI Output</h1>
              <div className="border border-gray-300 p-4 rounded-lg mt-2">
                <p className="text-gray-700">
                  <strong>Type: </strong> {store.aichain.aiOutput.type}
                </p>
                <p className="text-gray-700">
                  <strong>Created: </strong> {store.aichain.aiOutput.created}
                </p>
                <p className="text-gray-700">
                  <strong>AI Response: </strong>
                  {store.aichain.aiOutput.airesponse}
                </p>
              </div>
            </div>

            {/* <div className="my-4">
              <h2 className="text-2xl font-bold">AI Chain Data:</h2>
              <div className="border border-gray-300 p-4 rounded-lg mt-2">
                <pre className="text-gray-700">{formatJSON(store.aichain)}</pre>
              </div>
            </div> */}

            {/* <div className="my-4" style={{ height: "100vh", width: "100%" }}>
              <AiChainFlow />
            </div> */}
          </>
        )}
      </MainBody>
    );
  })
);

export default AiChainPage ;
