const { useEditor } = require('@craftjs/core');
const cx = require('classnames');
const React = require('react');

 const { Header } = require('./Header');
 const { Sidebar } = require('./Sidebar/index');
const { Toolbox } = require('./Toolbox');

const Viewport = ({ children }) => {
  const {
    enabled,
    connectors,
    actions: { setOptions },
  } = useEditor((state) => (
    {
    enabled: state.options.enabled,
    
  }));

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    console.log("Children", children)


    window.requestAnimationFrame(() => {
      // Notify doc site
      window.parent.postMessage(
        {
          LANDING_PAGE_LOADED: true,
        },
        '*'
      );

      setTimeout(() => {
        setOptions((options) => {
          options.enabled = true;
        });
      }, 200);
    });
  }, [setOptions]);

  return (

    <div className="viewport">
    <div className={cx(['flex h-full overflow-hidden flex-row w-full fixed'])}>
      <Toolbox />
      <div className="page-container flex flex-1 h-full flex-col">
        <Header />
        <div
          className={cx([
            'craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto',
            {
              'bg-renderer-gray': enabled,
            },
          ])}
          ref={(ref) =>
            connectors.select(connectors.hover(ref, null), null)
          }
        >
          <div className="relative flex-col flex items-center pt-8">
            {children}
          </div>
        </div>
      </div>
      <Sidebar />
    </div>
  </div>


);
};

export { Viewport }