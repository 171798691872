import React, { useEffect } from "react";
import MainBody from "./Components/Body";
import ReactPlayer from "react-player";
import { useHistory, useParams, Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import ReactTooltip from "react-tooltip";

const PlansPage = inject("store")(
  observer(({ store }) => {
    useEffect(() => {
      store.fetchPlans();
    }, [store]);

    return (
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        <VideoPlayer url="https://www.youtube.com/watch?v=hGekC71eugY&t=4s" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-between">
          {store.plans &&
            store.plans.map((plan, index) => <Card key={index} plan={plan} />)}
        </div>
      </MainBody>
    );
  })
);

export const VideoPlayer = ({ url }) => (
  <div className="intro-video-wrapper mb-4">
    <ReactPlayer url={url} playing={false} controls={true} width="100%" />
  </div>
);

export const Card = ({ plan }) => {
  if (plan.uiState === "hidden") {
    return null;
  }

  return (
    <Link to={`/plan/${plan._id}`} className="block">
      
      <div
        style={{minHeight: '500px'}}
        className={`w-full max-w bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-5 relative overflow-hidden pb-8
          ${plan.uiState === "coming soon" ? "opacity-70 bg-gray-200 border-gray-300" : ""}`}
      >
        <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
          {plan.name}
        </h5>

        <h5 className="text-xl tracking-tight text-indigo-600 dark:text-white ">
          {plan.category}
        </h5>

        <img className="p-8 rounded-t-lg" src={plan.image} alt="Plan image" />

        <div>
          <h5 className="text-xl tracking-tight text-gray-900 dark:text-white">
            {plan.description}
          </h5>
        </div>
        <Influencers influencers={plan.influencers} />
        {plan.status === "In progress" && (
          <ProgressBar percentage={plan.percentageCompletion} />
        )}
        <ReactTooltip place="bottom" type="info" effect="solid" />

        {plan.uiState === "coming soon" && (
        <div className="absolute bottom-0 left-0 w-full bg-gray-800 text-white text-center py-1 font-semibold z-10">
          Coming Soon
        </div>
      )}

      </div>
    </Link>
  );
};

// ProgressBar component
export const ProgressBar = ({ percentage }) => {
  return (
    <div className="w-full h-4 bg-gray-300 mt-4 rounded">
      <div
        className="h-full text-center text-xs text-white bg-indigo-500 rounded"
        style={{ width: `${percentage}%` }}
      >
        {percentage}%
      </div>
    </div>
  );
};

// Influencers component
export const Influencers = ({ influencers }) => (
  <div className="flex flex-wrap mt-2">
    {influencers &&
      influencers.map((influencer, idx) => (
        <div key={idx} className="mr-2 mb-2">
          <img
            src={influencer.photo}
            alt={influencer.name}
            className="w-12 h-12 rounded-full"
            data-tip={influencer.name}
          />
        </div>
      ))}
    <ReactTooltip place="bottom" type="info" effect="solid" />
  </div>
);

export default PlansPage;
