import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../Components/Button";
import appStore from "../store";
import { observer, inject } from "mobx-react";
import LoadingAnimation from "./Loading/LoadingAnimation";

import axios from "axios";
import "./FileUpload.css";

const FileUpload = inject("store")(
  observer((props) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [uploadStatus, setUploadStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showMessage, setShowMessage] = useState(true);

    // Run API to upload file
    const handleUpload = async (selectedFile) => {
      if (!selectedFile) return;

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("taskId", props.task._id); // Replace with your actual task ID

      try {
        setIsLoading(true);

        const response = await props.store.uploadImageVideo(
          formData,
          setProgress
        );
        console.log("response from uploading video:", response);

        setUploadStatus("File uploaded successfully");
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        setUploadStatus("Failed to upload file");
      } finally {
        setIsLoading(false);
      }
    };

    const handleCloseMessage = () => {
      setShowMessage(false);
    };

    // Run API to set
    const onDrop = useCallback((acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setFileName(selectedFile.name);
      handleUpload(selectedFile);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: "image/*,video/*",
    });

    const renderFileUpload = () => {
      return (
        <div {...getRootProps()} style={getDropzoneStyles(isDragActive)}>
          <input {...getInputProps()} />
          <div className="image-container">
            <img src="/upload.png" alt="Upload" />
          </div>
          {isDragActive ? (
            <p className="pt-3 pb-5">Drop the file here ...</p>
          ) : (
            <p>Drag and drop a file here, or click to select a file</p>
          )}
          {/* <Button title="Browse Files" /> */}
        </div>
      );
    };

    const showStatusMessage = (message) => {

      if (!showMessage) return null;

      return (
        <div
          className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 my-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{message}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" >
            <svg
              className="fill-current h-6 w-6 text-blue-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              onClick={handleCloseMessage}
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      );
    };

    const showLoading = () => {
      return (
        <>
          <div
            style={{
              ...getDropzoneStyles(),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <LoadingAnimation />
            </div>

            <div>{progress}</div>
          </div>
        </>
      );
    };

    return (
      <div>
        {isLoading ? showLoading() : renderFileUpload()}
        {!isLoading && uploadStatus && showMessage && showStatusMessage(uploadStatus)}
        {/* {fileName && <p>Selected file: {fileName}</p>} */}
      </div>
    );
  })
);

const getDropzoneStyles = (isDragActive) => ({
  border: "2px dashed gray",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  color: isDragActive ? "green" : "#CCCCCC",
  minHeight: "200px",
});

export default FileUpload;
