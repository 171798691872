import React, { useEffect, useState } from "react";

import MainBody from "./Components/Body";
import { TaskItem } from "./Components/TaskItem";
import { useHistory, useParams } from "react-router-dom";
import Accordion from "./Components/Accordion";
import LoadingAnimation from "./Components/Loading/LoadingAnimation";

import ReactPlayer from "react-player";
import Button from "./Components/Button"; // If you don't use this, you can remove it.
import { ClockIcon } from "@heroicons/react/outline"; // If you don't use this, you can remove it.

import { observer, inject } from "mobx-react";

const PlanPage = inject("store")(
  observer(({ store }) => {
    let { planId } = useParams();

    useEffect(() => {
      const profile = JSON.parse(localStorage.getItem("profile"));
      const userId = profile?._id;
      console.log("UserId", userId);
    
      if (!planId) {
        planId = profile?.defaultPlanId;
      }
    
      console.log("planId", planId);
    
      store.fetchPlan(planId).then(() => {
        if (store.plan?.checklists?.length > 0) {
          const firstChecklist = store.plan.checklists.find((checklist) => checklist !== null);
          if (firstChecklist?.tasks?.length > 0) {
            console.log("planData", firstChecklist.tasks[0]);
          }
        }
      });
    }, [planId, store]);
    
    

    if (store.planLoading) {
      return (
        <div className="flex justify-center items-center h-screen w-screen">
          <LoadingAnimation />
        </div>
      );
    }

    if (store.planError) {
      return <div>Error: {store.error}</div>;
    }

    return (
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        {store.plan && store.plan.uiState === "coming soon" && (
          <Alert message="Something spectacular is brewing. Watch this space!" />
        )}

        {store.plan && store.plan.videoUrl && (
          <div className="intro-video-wrapper mb-4">
            <ReactPlayer
              url={store.plan.videoUrl}
              playing={false}
              controls={true}
              width="100%"
            />
          </div>
        )}

{store.plan &&
  Array.isArray(store.plan.checklists) &&
  store.plan.checklists
    .filter((checklist) => checklist && Array.isArray(checklist.tasks)) // Filter out null checklists
    .map((checklist, index) => (
      <Accordion
        key={index}
        title={checklist.title || "Untitled Checklist"}
        isOpen={index === 0} // Open the first checklist by default
      >
        {checklist.tasks.length > 0 ? (
          <Grid tasks={checklist.tasks} />
        ) : (
          <p>No tasks available</p>
        )}
      </Accordion>
    ))}



      </MainBody>
    );
  })
);

export const Alert = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="bg-blue-200 border-l-4 border-blue-500 text-blue-700 p-4 mb-5 relative"
      role="alert"
    >
      <p className="font-bold">Info</p>
      <p>{message}</p>
      <button
        className="absolute top-0 right-0 px-4 py-2"
        onClick={() => setIsVisible(false)}
      >
        ×
      </button>
    </div>
  );
};

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    {" "}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ tasks }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-1 xl:grid-cols-1 ">
    {tasks.map((task, index) => (
      <TaskItem
        key={index}
        task={task}
        taskId={task._id}
        isCompleted={task.isCompleted}
        group={task.category}
        title={task.title}
        to={task.to}
        Icon={task.icon}
        desc={task.desc}
        fromColor={task.fromColor}
        toColor={task.toColor}
        isHuman={task.isHuman}
        manualTaskInProgress={task.manualTaskInProgress}
      />
    ))}
  </div>
);

export default PlanPage;
