import React, { useState, useEffect } from "react";
import MainBody from "./Components/Body";
import { TaskItem } from "./Components/TaskItem";
import ReactPlayer from "react-player";
import Button from "./Components/Button";
import { ClockIcon } from "@heroicons/react/outline";
import { observer, inject } from "mobx-react";

const Body = (props) => {
  const [error, setError] = useState("");
  const [output, setOutput] = useState("");
  const [code, setCode] = useState("");
  const [outputs, setOutputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [currentPrompt, setCurrentPrompt] = useState(0);
  const [task, setTask] = useState({});
  const [nextTask, setNextTask] = useState({});
  const [currentOption, setCurrentOption] = useState("Start Using");
  const [airesponse, setAiResponse] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [date, setDate] = useState(Date.now() + 1000);
  const [countdown, setCountdown] = useState([]);

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const userId = profile._id;
    console.log("UserId", userId);
    props.store.getTasks(userId);
  }, [props]);

  const permissions = props.store.tasks.filter((task) =>
    task.permissions.some((r) => props.store.profile.permissions.includes(r))
  );

  const sortTasksByOrder = (tasks) => {
    return tasks.sort((a, b) => a.order - b.order);
  };

  const runAllTasks = () => {
    const { tasks } = props.store;
    const orderedTasks = [
      ...offerGeneration(),
      ...audienceGeneration(),
      ...leadGeneration(),
      ...leadNurturing(),
      ...salesConversation(),
    ];

    console.log("Ordered Tasks:", orderedTasks);

    for (const task of orderedTasks) {
      if (task.api) {
        if (!task.isCompleted) {
          if (task.isAutonomous === true) {
            console.log("Task", task.api);
            console.log("Title", task.title);
            console.log("completed", task.isCompleted);
            console.log("isAutonomous", task.isAutonomous);
            console.log("task_id", task._id);

            props.store.getTaskById(task._id).then(async (task) => {
              console.log("task", task);

              if (Array.isArray(task.prompts)) {
                setPrompts([task.prompts]);
                setCurrentValue(
                  task.prompts[currentPrompt].prompts[0].value
                );
                setOutput(task.output.airesponse);

                await delay(5000);

                const success = await onAutonomousMode(task);
                await delay(10000);
              } else {
                console.log("Error: task.prompts is not iterable.");
              }
            }).catch((error) => {
              console.log("Error fetching task:", error);
            });
          } else if (task.isAutonomous === false) {
            console.log("Automous is false", task.title);
            const updateTaskInprogress = updateTasktoInprogress(task._id);
            props.store.TASKS = updateTaskInprogress;
            break;
          }
        }
      }
    }
  };

  const onAutonomousMode = (task) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("");
        setError("");
        setOutput("");
        setCode("");
        setOutputs([]);
        setLoading(true);

        let checkMinimumPrompts = checkMinimumPrompts(task);

        let postObj = {};

        task.prompts[currentPrompt].prompts.forEach((prompt) => {
          postObj[prompt.attr] = prompt.value;
        });

        postObj.currentPrompt = task.prompts[currentPrompt].title;

        if (task.prompts[currentPrompt].n) {
          postObj.n = prompts[currentPrompt].n;
        }

        if (task.api.includes("sendprompter")) {
          postObj.businessName = JSON.parse(
            localStorage.getItem("profile")
          ).company;
        }

        postObj.taskId = task._id;
        console.log("Post Obj", postObj);

        let response = await props.store.api.post(task.api, postObj);

        if (!response.data.success) {
          const updateTaskInprogress = updateTasktoInprogress(task._id);
          props.store.TASKS = updateTaskInprogress;
          return resolve(false);
        }

        if (response.data.output) {
          const airesponseupdates = updateTaskswithAiResponse(
            task._id,
            response.data.output
          );
          props.store.TASKS = airesponseupdates;
        }

        if (response.data.code) {
          setCode(response.data.code);
        }

        if (response.data.outputs) {
          setOutputs(response.data.outputs);
        }

        setLoading(false);
        resolve(true);
      } catch (error) {
        countdown.forEach((countdown) => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        setLoading(false);
        reject(error);
      }
    });
  };

  const updateTasktoInprogress = (taskId) => {
    const updatedTasks = props.store.TASKS.map((task) => {
      if (task._id === taskId) {
        return {
          ...task,
          isCompleted: "inprogress",
        };
      }
      return task;
    });

    return updatedTasks;
  };

  const updateTaskswithAiResponse = (taskId, newAiResponse) => {
    const updatedTasks = props.store.TASKS.map((task) => {
      if (task._id === taskId) {
        return {
          ...task,
          output: {
            ...task.output,
            airesponse: newAiResponse,
          },
          isCompleted: true,
        };
      }
      return task;
    });

    return updatedTasks;
  };

  const checkMinimumPrompts = (task) => {
    let shouldReturn = false;

    task.prompts[currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      }
    });

    return shouldReturn;
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const offerGeneration = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Offer Generation")
    );
  };

  const audienceGeneration = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Audience Generation")
    );
  };

  const leadGeneration = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Lead Generation")
    );
  };

  const leadNurturing = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Lead Nurturing")
    );
  };

  const salesConversation = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Sales Conversation")
    );
  };

  const onboarding = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Onboarding")
    );
  };

  const fullfillmentSupport = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Fullfillment Support")
    );
  };

  const productDevelopment = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Product Development")
    );
  };

  const referralAndUpsell = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Referral and Upsell")
    );
  };

  const humanResources = () => {
    return sortTasksByOrder(
      permissions.filter((task) => task.category === "Human Resources")
    );
  };

  return (
    <>
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        <div className="intro-video-wrapper mb-4">
          <ReactPlayer
            url="https://upclub.s3.amazonaws.com/TheStrategy_ALTERED.mp4"
            playing={false}
            controls={true}
            width="100%"
          />
        </div>
        <div className="flex justify-end items-center">
          <Button
            title="Complete Entire Plan"
            Icon={ClockIcon}
            onClick={runAllTasks}
            className="bg-green-500 text-white hover:bg-green-600"
          />
        </div>

        {offerGeneration().length ? (
          <>
            <Title title="Craft an irresistible offer" />
            <Grid tasks={offerGeneration()} />
            <Divider />
          </>
        ) : null}

        {audienceGeneration().length ? (
          <>
            <Title title=" Create 60 Second Offer Ad" />
            <Grid tasks={audienceGeneration()} />
            <Divider />
          </>
        ) : null}

        {leadGeneration().length ? (
          <>
            <Title title="Get Appointments" />
            <Grid tasks={leadGeneration()} />
            <Divider />
          </>
        ) : null}

        {leadNurturing().length ? (
          <>
            <Title title="Nurture Leads to Book Even More Appointments" />
            <Grid tasks={leadNurturing()} />
            <Divider />
          </>
        ) : null}

        {salesConversation().length ? (
          <>
            <Title title="Close Deals" />
            <Grid tasks={salesConversation()} />
            <Divider />
          </>
        ) : null}

        {onboarding().length ? (
          <>
            <Title title="Onboarding" />
            <Grid tasks={onboarding()} />
            <Divider />
          </>
        ) : null}

        {fullfillmentSupport().length ? (
          <>
            <Title title="Fullfillment Support" />
            <Grid tasks={fullfillmentSupport()} />
            <Divider />
          </>
        ) : null}

        {productDevelopment().length ? (
          <>
            <Title title="Product Development" />
            <Grid tasks={productDevelopment()} />
            <Divider />
          </>
        ) : null}

        {humanResources().length ? (
          <>
            <Title title="Human Resources" />
            <Grid tasks={humanResources()} />
            <Divider />
          </>
        ) : null}

        {referralAndUpsell().length ? (
          <>
            <Title title="Referral and Upsell" />
            <Grid tasks={referralAndUpsell()} />
            <Divider />
          </>
        ) : null}
      </MainBody>
    </>
  );
};

const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    <div></div>
    <div></div>
  </div>
);

const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ tasks }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-1 xl:grid-cols-1 ">
    {tasks.map((task, index) => (
      <TaskItem
        key={index}
        taskId={task._id}
        isCompleted={task.isCompleted}
        group={task.category}
        title={task.title}
        to={task.to}
        Icon={task.icon}
        desc={task.desc}
        fromColor={task.fromColor}
        toColor={task.toColor}
        isHuman={task.isHuman}
        manualTaskInProgress={task.manualTaskInProgress}
      />
    ))}
  </div>
);

export default inject("store")(observer(Body));


