import React, { useState, useEffect } from 'react';
import {
    PencilIcon,
    ClockIcon,
} from '@heroicons/react/outline';

import { Grid, Col } from '../Components/Body';
import EntryText from '../Components/EntryText';
import Button from '../Components/Button';
import OutputUI from '../Components/OutputUI';
import Logo from '../Logo';
import Countdown from 'react-countdown';

import { useObservable, useObserver } from 'mobx-react';

function Referral(props) {
    const [countdown, setCountdown] = useState(null);
    const [output, setOutput] = useState('');
    const [outputs, setOutputs] = useState([]);
    const [outputError, setOutputError] = useState('');
    const [option, setOption] = useState('Start Using');
    const [options, setOptions] = useState([]);
    const [currentOption, setCurrentOption] = useState('');
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(Date.now() + 1000);
    const [prompt, setPrompt] = useState('');
    const [promptError, setPromptError] = useState('');
    const [promptNumber, setPromptNumber] = useState(0);
    const [promptOptions, setPromptOptions] = useState([]);
    const [currentPromptOption, setCurrentPromptOption] = useState('');
    const [prompts, setPrompts] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [task, setTask] = useState({});

    const init = async () => {
        await refreshFeedback();
    };

    const refreshFeedback = async () => {
        try {
            let response = await props.store.api.post('/user/feedback/view');
            setFeedbacks([...response.data]);
        } catch (err) {
            console.error(err);
        }
    };

    const handleFeedback = async () => {
        try {
            await props.store.api.post('/user/feedback', { feedback: props.store.feedback });
            await refreshFeedback();
            setOutput('Thank you for your feedback!');
            props.store.feedback = '';
        } catch (err) {
            console.log(err);
            setOutput('There seems to have been an error, please try submitting again');
        }
    };

    const onClick = async () => {
        setLoading(true);
        await handleFeedback();
        setDate(Date.now() + 10000);
        props.store.feedback = '';
        countdown.start();
        setLoading(false);
    };

    const onChange = (e) => {
        props.store.feedback = e.target.value;
    };

    useEffect(() => {
        init();
    }, []);

    return useObserver(() => (
        <>
            <Grid>
                <Col span="6">
                    <EntryText
                        title="Feedback"
                        desc="Provide some feedback about your experience"
                        prompt={props.store.feedback}
                        onChange={onChange}
                    />
                    <Countdown
                        ref={(countdown) => setCountdown(countdown)}
                        date={date}
                        renderer={(props) => (
                            <Button
                                title={props.total ? `Timeout ${props.total / 1000} secs` : 'Submit Feedback'}
                                disabled={props.total}
                                Icon={props.total ? ClockIcon : PencilIcon}
                                onClick={onClick}
                            />
                        )}
                    />
                </Col>
                <Col span="6">
                    <OutputUI
                        title={`EnhanceAI.ai`}
                        desc={`Feedback Response`}
                        Icon={Logo}
                        fromColor={`yellow-300`}
                        toColor={`yellow-400`}
                        loading={loading}
                        output={output}
                    />
                    {feedbacks &&
                        feedbacks.map((feedback, index) => (
                            <OutputUI
                                key={feedback._id}
                                title={`Feedback Received`}
                                desc={`${feedback.created}`}
                                Icon={PencilIcon}
                                fromColor={feedback.response ? `green-400` : `gray-300`}
                                toColor={feedback.response ? `green-600` : `gray-400`}
                                output={feedback.feedback}
                                outputs={feedback.response ? [feedback.response] : null}
                            />
                        ))}
                </Col>
            </Grid>
        </>
    ));
}

export default Referral;
