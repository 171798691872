import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline"; // Import ChevronDownIcon
import "./Accordion.css"; 


const Accordion = ({ title, children, isOpen: initialOpenState = false }) => {
  const [isOpen, setIsOpen] = useState(initialOpenState);

  return (
    <div className="accordion-wrapper">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="accordion-title flex justify-between items-center px-4 py-6 w-full text-left outline-none focus:outline-none transition duration-300"
      >
        <span>{title}</span>
        <ChevronDownIcon 
          className={`w-5 h-5 transform transition-transform duration-300 ${isOpen ? "rotate-0" : "rotate-180"}`} 
        />
      </button>
      <div className={`accordion-content ${isOpen ? "open" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
