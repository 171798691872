const Logo = () => (

	<img
    src="/tre.png"
    alt="Logo"
    className="w-10 h-10 inline-block"
  />
	
)

export default Logo;