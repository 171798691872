import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

const TextareaInput = inject("store")(
  observer((props) => {
    const { uiInput, handleInputChange, store } = props;
    const [inputValue, setInputValue] = useState(uiInput.value || "");

    useEffect(() => {
      // Check if uiInput has aiOutputFrom and it matches store.task._id
      if (
        uiInput.aiOutputFrom &&
        uiInput.aiOutputFrom === store.task._id &&
        store.task.aichain?.aiOutput?.airesponse
      ) {
        setInputValue(store.task.aichain.aiOutput.airesponse);
      }
    }, [uiInput, store.task]);

    const handleChange = (e) => {
      setInputValue(e.target.value);
      handleInputChange(e, uiInput.attr); // Call the parent's handleInputChange with the updated value
    };

    const calculateRows = (text) => {
      // Calculate the number of rows based on the number of newlines
      const calculatedRows = text.split("\n").length;
      // Ensure that the minimum number of rows is 5
      return calculatedRows >= 5 ? calculatedRows : 5;
    };

    const rows = calculateRows(inputValue);

    return (
      <div className="mb-4">
        <label
          htmlFor={uiInput.attr}
          className="block text-sm font-medium text-gray-700"
        >
          {uiInput.title}
        </label>
        <textarea
          id={uiInput.attr}
          name={uiInput.attr}
          value={inputValue}
          onChange={handleChange}
          placeholder={uiInput.placeholder}
          maxLength={uiInput.maxLength}
          minLength={uiInput.min}
          required={uiInput.required}
          rows={rows} // Set the rows attribute dynamically
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        {uiInput.error && (
          <p className="mt-2 text-sm text-red-600">{uiInput.error}</p>
        )}
      </div>
    );
  })
);

export default TextareaInput;
