import React, { useState, useRef, useEffect } from "react";
import './Chat.css';
import appStore from '../store'
import { Editor } from '@tinymce/tinymce-react';
//import tinymce from "tinymce/tinymce";
import axios from "axios";



function MCEEditor({ props, task, prompt}) {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  console.log("Prompt Input selected", prompt.value)


  const handleSubmit = async (e)  => {
    e.preventDefault();

    setLoading(true);
    const content = editorRef.current.getContent({ format: "html" });
    console.log("Content", content);
    console.log("Tasks", task.api)
    setLoading(false);

    let response = await props.store.api
                .post(task.api, {content : content})
        
        if(response.data.link){
          setLink(response.data.link);
         setLoading(false);
        }

        console.log('response', response.data.link);

    setLoading(false); // Set the loading state to false


    // axios
    //   .post("/api/create-slides", { content })
    //   .then((response) => {
    //     setLink(response.data.link);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     setLoading(false);
    //   });

  };
  
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <div>
      {/* <h1>Create Outline to be converted</h1> */}
    <Editor
          apiKey="ly49dd1nt50vgcj71zmcbhowvc6jp4qmv9g2l24c99ngz5uz"
          onInit={(evt, editor) => editorRef.current = editor}
         initialValue={prompt.value}
         //initialValue= "Line 1\nLine 2\nLine 3"
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
       />
{/* 
      <button onClick={handleSubmit}>Create Slides</button>
      {loading && <div>Loading...</div>}
      {link && (
        <div>
          <a href={link} target="_blank" rel="noopener noreferrer">
            View Slides
          </a>
        </div>
      )} */}


    </div>
  );
}

export default MCEEditor;