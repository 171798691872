import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { computed } from "mobx";
import Body from "./Components/Body";
import { observer, inject } from "mobx-react";
import { useLocation, NavLink, useHistory, withRouter } from "react-router-dom";
import {
  SwitchHorizontalIcon,
  ScaleIcon,
  DatabaseIcon,
  UserCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { IconDashboard } from "./Icons";

const NavBar = inject("store")(
  observer((props) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      if (location.pathname === "/signup" || location.pathname === "/login") {
        history.push("/");
      }

      document.addEventListener("keydown", shortcutHandler);
      return () => document.removeEventListener("keydown", shortcutHandler);
    }, [location, history]);

    const shortcutHandler = (e) => {
      if (e.keyCode === 75 && e.ctrlKey) {
        e.preventDefault();
        document.getElementById("q").focus();
        document.getElementById("q").select();
      }
    };

    const onKeyUp = (e) => {
      if (location.pathname !== "/search") {
        history.push("/search");
      }
      if (e.keyCode === 8) {
        if (props.store.tasksKeyword === "") {
          history.push("/");
        }
      }
    };

    const fromColor = computed(() => {
      if (props.store.profile.credits <= 0) {
        return "bg-red-200 text-red-600";
      }
      if (
        props.store.profile.status === "trialing" ||
        props.store.profile.status === "active" ||
        props.store.profile.status === "incomplete"
      ) {
        return "";
      }
      return "bg-red-200 text-red-600";
    });

    return (
      <>
        <div className="border-b border-gray-300 bg-white shadow-sm ">
          <div className="container flex justify-between mx-auto px-4 md:px-28 select-none relative">
            <div className="flex">
              {/* Added justify-between and relative */}
              <NavLink
                to="/"
                exact
                tabIndex={-1}
                onClick={() => (props.store.tasksKeyword = "")}
                activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
                className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 font-medium transition items-center"
              >
                {/* <IconDashboard className="w-7 h-7 lg:mr-4 transition" /> */}
                <div className="hidden lg:block">Checklist</div>
              </NavLink>

              <NavLink
                to="/plans"
                activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
                className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 font-medium transition items-center"
              >
                <div className="hidden lg:block">Plans</div>
              </NavLink>
            </div>
            <Logo />
            <div>
              <Dropdown handleLogout={props.store.handleLogout} />
            </div>
          </div>
        </div>
        {props.children}
      </>
    );
  })
);

export default withRouter(NavBar);

const Logo = () => (
  <img
    src="/tre.png"
    alt="Logo"
    className="w-24 h-24 absolute left-1/2 transform -translate-x-1/2" // Adjusted the classes here
  />
);

const Dropdown = ({handleLogout}) => {
  const [isOpen, setIsOpen] = useState(false);
  let timeoutId;

  const handleMouseLeave = () => {
    // Set a timeout to close the dropdown after 1 second (1000 milliseconds)
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  const handleMouseEnter = () => {
    // Clear any existing timeout when mouse enters, to prevent it from closing if re-entered within the 1-second delay
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  return (
    <div className="relative h-100">
      <div
        className="flex justify-center items-center align-center cursor-pointer h-100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <UserCircleIcon className="w-9 py-3 transition h-100"  />
        <ChevronDownIcon className="w-5 ml-2 transition h-100" />
      </div>
      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-48 py-2 bg-white border rounded shadow-xl z-40"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          <NavLink
            to="/profile"
            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Profile
          </NavLink>
          <div
            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
            onClick={handleLogout}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

const Input = styled.input``;

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

// transition:ease-in-out 0.5s margin-top;
// 	transition-delay:0.05s;

const SuperHeader = styled.div`
  height: 150px;
  background: white;
  margin-top: ${(props) => (props.active ? "0px" : "-150px")};
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  background-size: auto 50%;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
`;

const SearchBar = (props) => {
  return (
    <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
      <label
        htmlFor="q"
        className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 "
      >
        <div
          type="submit"
          className="p-2 focus:outline-none focus:shadow-outline "
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-6 h-6 transition"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
      </label>
      <Input
        type="search"
        tabIndex={-1}
        id="q"
        name="q"
        className="py-4 pl-4 md:pl-14 text-xl focus:outline-none focus:bg-white focus:text-gray-900 transition flex flex-1 w-full"
        placeholder="Search...  [Shortcut: Ctrl + K]"
        autoComplete="off"
        value={props.store.tasksKeyword}
        onChange={props.store.onChangeTasksKeyword}
        onKeyUp={props.onKeyUp}
      />
    </div>
  );
};
