import { RotatingLines } from "react-loader-spinner";

function Button({ title, onClick, Icon, disabled, children, loading }) {
  return (
    <button
      className={
        (disabled || loading)
          ? `select-none py-3 px-6 border-t-2 border-gray-300 bg-gray-300 hover:bg-gray-400 disabled hover:to-gray-700 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition inline-flex items-center justify-center w-48`
          : `select-none py-3 px-6 border-t-2 border-gray-400 bg-green-500 hover:bg-gray-600 hover:via-blue-700 hover:bg-green-600 text-white  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition inline-flex items-center justify-center w-48`
      }
      onClick={disabled ? null : onClick}
      disabled={loading}
    >
      {Icon && !loading && (
        <Icon
          className={
            disabled
              ? `h-6 w-6 mr-2 text-gray-100`
              : "h-6 w-6 mr-2 text-gray-200"
          }
        />
      )}

      {loading ? (
        <span>
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="25"
            visible={true}
          />
        </span>
      ) : (
        <span>{title}</span>
      )}

      {children}
    </button>
  );
}

export default Button;
