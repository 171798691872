import Logo from "../Logo";

const LoadingAnimation = () => (
    <div
      style={{
        margin: "10px",
        padding: "10px",
        maxWidth: "100%",
        wordWrap: "break-word",
        borderRadius: "10px",
        color: "black",
        display: "flex",
      }}
    >
      <Logo />
      <div style={{ flex: 1 }}>
        <span className="loading-dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    </div>
  );

export default LoadingAnimation;