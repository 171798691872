import React from 'react';
import styled from 'styled-components';
import { useNode } from '@craftjs/core';

import { ButtonSettings } from './ButtonSettings';
import { Text } from '../Text';

const StyledButton = styled.button`
  background: ${(props) =>
    props.buttonStyle === 'full'
      ? `rgba(${Object.values(props.background)})`
      : 'transparent'};
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.buttonStyle === 'outline'
      ? `rgba(${Object.values(props.background)})`
      : 'transparent'};
  margin: ${({ margin }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

const Button = () => {
  const {
    connectors: { connect },
    selected,
  } = useNode();

  return (
    <StyledButton
      ref={connect}
      className={`
        rounded w-full px-4 py-2
        ${selected ? 'shadow-lg' : ''}
      `}
    >
      <Text text="Button" />
    </StyledButton>
  );
};

Button.craft = {
  displayName: 'Button',
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    buttonStyle: 'full',
    margin: ['5', '0', '5', '0'],
  },
  related: {
    toolbar: ButtonSettings,
  },
};

export {Button};
