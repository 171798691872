import React from 'react';
import styled from 'styled-components';


const SidebarItemDiv = styled.div`
  height: ${(props) =>
    props.visible && props.height && props.height !== 'full'
      ? `${props.height}`
      : 'auto'};
  flex: ${(props) =>
    props.visible && props.height && props.height === 'full' ? `1` : 'unset'};
  color: #545454;
`;

const Chevron = styled.a`
  transform: rotate(${(props) => (props.visible ? 180 : 0)}deg);
  svg {
    width: 8px;
    height: 8px;
  }
`;

const SidebarItem = ({
  visible,
  icon,
  title,
  children,
  height,
  onChange,
}) => {

  const IconComponent = icon; // Convert icon to a React component

  return (
    <SidebarItemDiv visible={visible} height={height} className="flex flex-col">
      <div
        onClick={() => {
          if (onChange) onChange(!visible);
        }}
        className={`cursor-pointer bg-white border-b last:border-b-0 flex items-center px-2 ${
          visible ? 'shadow-sm' : ''
        }`}
      >
        <div className="flex-1 flex items-center">
          {/* {React.createElement(icon, { className: 'w-4 h-4 mr-2' })} */}
          {/* {IconComponent && <IconComponent className="w-4 h-4 mr-2" />} */}

          <h2 className="text-xs uppercase">{title}</h2>
        </div>
        <Chevron visible={visible}>
        <img src="/icons/arrow.svg" alt="Arrow" />

        </Chevron>
      </div>
      {visible ? (
        <div className="w-full flex-1 overflow-auto">{children}</div>
      ) : null}
    </SidebarItemDiv>
  );
};

export {SidebarItem};
