import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import ReactQuill from "react-quill";

const TextEditor = inject("store")(
  observer((props) => {
    const { uiInput, handleInputChange, store } = props;
    const [inputValue, setInputValue] = useState(uiInput.value || "");

    useEffect(() => {

      if (
        uiInput.aiOutputFrom &&
        uiInput.aiOutputFrom === store.task._id &&
        store.task.aichain?.aiOutput?.airesponse
      ) {
        setInputValue(store.task.aichain.aiOutput.airesponse);
      } else {
        setInputValue(uiInput.value || "");
      }
    }, [uiInput, store.task]);


    const handleQuillChange = (htmlContent, delta, source, editor) => {
      // Replace <br> with \n in the content
      const textContent = htmlContent.replace(/<br>/g, "\n");
      setInputValue(textContent);


      let event = {
        target: {
          value: textContent
        }
      }
      
      handleInputChange(event, uiInput.attr); // Call the parent's handleInputChange with the updated value
      

    };

    return (
      <div className="mt-4 bg-white">
        <ReactQuill
          value={inputValue.replace(/\n/g, "<br>")}
          onChange={handleQuillChange}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              ["link"],
              [{ linebreak: "\n" }],
            ],
          }}
          formats={[
            "header",
            "font",
            "size",
            "list",
            "bold",
            "italic",
            "underline",
            "link",
            "linebreak",
          ]}
        />
      </div>
    );
  })
);

export default TextEditor;
