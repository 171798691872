import React from 'react';
import MainBody from './Components/Body';
import { Grid } from './Dashboard';
import { TaskItem } from "./Components/TaskItem";
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

const Search = (props) => {
  const permissions = computed(() => {
    return props.store.tasks.filter(task =>
      task.permissions.some(r => props.store.profile.permissions.includes(r))
    );
  });

  return (
    <>
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
        <Grid>
          {permissions.map((task, index) =>
            <TaskItem
              key={index}
              group={task.category}
              title={task.title}
              to={task.to}
              Icon={task.Icon}
              desc={task.desc}
              fromColor={task.fromColor}
              toColor={task.toColor}
            />)}
        </Grid>
      </MainBody>
    </>
  );
}

export default inject('store')(observer(Search));
