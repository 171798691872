import React, { useEffect, useState } from "react";

import MainBody from "./Components/Body";
import { TaskItem } from "./Components/TaskItem";
import { useHistory, useParams, Link } from "react-router-dom";
import LoadingAnimation from "./Components/Loading/LoadingAnimation";
import { Helmet } from "react-helmet";

import Header from "./Components/Header";
import MCEEditor from "./Components/MCEEditor";
import TextareaInput from "./Components/TaskUiInputs/TextareaInput"; // Import the new component
import TextEditor from "./Components/TaskUiInputs/TextEditor"; // Import the new component

import ReactPlayer from "react-player";
import Body, { Grid, Col } from "./Components/Body";

import Button from "./Components/Button"; // If you don't use this, you can remove it.
import { ClockIcon, SaveIcon } from "@heroicons/react/outline"; // If you don't use this, you can remove it.

import { observer, inject } from "mobx-react";

const TaskPage = inject("store")(
  observer(({ store }) => {
    let { taskId } = useParams();

    const [hasChanges, setHasChanges] = useState(false); // State to track changes

    const handleInputChange = (e, attr) => {
      // You can update the state or perform any necessary actions.
      const newValue = e.target.value;
      console.log("newValue", newValue);
      console.log("attr", attr);

      // Find the corresponding uiInput in store.task.uiInputs
      const updatedUiInputs = store.task.ui.uiInputs.map((uiInput) => {
        if (uiInput.attr === attr) {
          // Update the value of the matching uiInput
          return { ...uiInput, value: newValue };
        }
        return uiInput;
      });

      store.updateTaskUiInputs(updatedUiInputs);

      // Update store.task with the modified uiInputs
      setHasChanges(true);
    };

    const handleSaveUiInputs = () => {
      if (store.taskLoading) {
        return; // Disable save button when the task is loading
      }

      // Implement logic to save changes
      store.saveTaskAndRunAutomation().then(() => {
        setHasChanges(false); // Reset changes flag after saving
      });
    };

    const handleUiOutputChange = (e, attr) => {
      const newValue = e.target.value;
      console.log("newValue", newValue);
      console.log("attr", attr);

      // Find the corresponding uiOutput in store.task.uiOutputs
      const updatedUiOutputs = store.task.ui.uiOutputs.map((uiOutput) => {
        if (uiOutput.attr === attr) {
          // Update the value of the matching uiOutput and create a 'created' date
          return {
            ...uiOutput,
            value: newValue,
            created: new Date().toISOString(),
          };
        }
        return uiOutput;
      });

      // Update store.task with the modified uiOutputs
      store.updateTaskUiOutputs(updatedUiOutputs);

      // Update store.task.aichain.aiOutput.airesponse
      const updatedAiResponse = newValue; // Assuming 'attr' corresponds to 'airesponse'
      store.updateTaskAiResponse(updatedAiResponse);

      setHasChanges(true);
    };

    const handleSaveUiOutputs = () => {
      if (store.taskLoading) {
        return; // Disable save button when the task is loading
      }

      // Implement logic to save changes
      store.saveTaskAndUpdateAiOutput().then(() => {
        setHasChanges(false); // Reset changes flag after saving
      });
    };

    useEffect(() => {
      // Fetch task here
      store.fetchTask(taskId);
    }, [taskId, store]);

    if (store.taskError) {
      return <div>Error: {store.error}</div>;
    }

    return (
      <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        {store.task && (
          <>
            <Helmet>
              <title>{`${store.task.title} Task`}</title>
            </Helmet>

            <Header
              title={store.task.title}
              desc={store.task.description}
              icon={store.task.icon}
              fromColor=""
              category={store.task.category}
              currentOption=""
            />
            {store.task && store.task.aichain && (
              <div className="flex justify-end">
                <Link
                  to={`/aichain/${store.task.aichain._id}`}
                  className="block"
                >
                  aichain
                </Link>
              </div>
            )}
          </>
        )}

        {store.task && store.task.video && (
          <div className="intro-video-wrapper mb-4">
            <ReactPlayer
              url={store.task.video}
              playing={false}
              controls={true}
              width="100%"
            />
          </div>
        )}

        <UiInputs
          store={store}
          handleInputChange={handleInputChange}
          handleSave={handleSaveUiInputs}
        />

        <UiOutputs
          store={store}
          handleUiOutputChange={handleUiOutputChange}
          handleSave={handleSaveUiOutputs}
        />
      </MainBody>
    );
  })
);

const UiInputs = inject("store")(
  observer(({ store, handleInputChange, handleSave }) => {
    const uiInputs = store.task && store.task.ui && store.task.ui.uiInputs;

    return (
      <Grid>
        <Col span="12">
          <UiElementSection
            uiOutputs={uiInputs}
            handleUiOutputChange={handleInputChange}
            handleSave={handleSave}
            taskLoading={store.taskLoading}
            store={store}
            hideIfEmpty={false}
          />
        </Col>
      </Grid>
    );
  })
);

const UiOutputs = inject("store")(
  observer(({ store, handleUiOutputChange, handleSave }) => {
    const uiOutputs = store.task && store.task.ui && store.task.ui.uiOutputs;

    return (
      <Grid>
        <Col span="12">
          <UiElementSection
            uiOutputs={uiOutputs}
            handleUiOutputChange={handleUiOutputChange}
            handleSave={handleSave}
            taskLoading={store.taskOutputLoading}
            hideIfEmpty={false}
          />
        </Col>
      </Grid>
    );
  })
);

const UiElementSection = inject("store")(
  observer(
    ({
      uiOutputs,
      handleUiOutputChange,
      handleSave,
      taskLoading,
      hideIfEmpty,
      store,
    }) => {
      const allUiElementsIsEmpty = (uiElements) => {
        if (hideIfEmpty) {
          return false;
        }

        for (let i = 0; i < uiElements.length; i++) {
          const uiElement = uiElements[i];

          // If there is a value in uiElement.value or a value in store.task.aichain.aiOutput.airesponse, continue checking the next uiElement.
          if (uiElement.value || store.task.aichain?.aiOutput?.airesponse) {
            return false;
          }

          console.log(
            "uiElement.value",
            `'${uiElement.value}', '${store.task.aichain?.aiOutput?.airesponse}'`
          );

          console.log("store",  store.task.ui.uiInputs)
          // If there is no value in uiElement.value and no value in store.task.aichain.aiOutput.airesponse, return true.
        }
        // If none of the uiElements met the condition, return false.
        return true;
      };

      return (
        <div>
          {uiOutputs &&
            uiOutputs.length > 0 &&
            !allUiElementsIsEmpty(uiOutputs) && (
              <div>
                {uiOutputs.map((uiOutput, index) => {
                  if (uiOutput.type === "textarea") {
                    return (
                      <TextareaInput
                        key={`${index}_${uiOutput.attr}`} // Add a unique key to force re-renders
                        uiInput={uiOutput}
                        handleInputChange={handleUiOutputChange}
                      />
                    );
                  } else if (uiOutput.type === "editor") {
                    return (
                      <TextEditor
                        key={`${index}_${uiOutput.attr}`}
                        uiInput={uiOutput}
                        handleInputChange={handleUiOutputChange}
                      />
                    );
                  } else {
                    // Render other UI elements based on their type here
                    return (
                      <div key={index}>
                        {/* Render other UI elements here */}
                      </div>
                    );
                  }
                })}

                <div className="flex justify-end mt-4">
                  <Button
                    title="Save"
                    Icon={SaveIcon}
                    onClick={handleSave}
                    loading={taskLoading}
                    className="mr-2"
                  />
                </div>
              </div>
            )}
        </div>
      );
    }
  )
);

export default TaskPage;
