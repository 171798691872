function Header({
  title,
  category,
  desc,
  icon,
  options,
  currentOption,
  fromColor,
  children,
}) {
  return (
<div className="md:pt-4 mb-px">
  <div className="container mx-auto flex items-center justify-start"> {/* Changed justify-center to justify-start for left alignment */}
    <div>
      {/* <div className={`text-sm font-medium text-${fromColor ? fromColor : "green-500"} -mb-1`}>
            {category}
        </div> */}
      <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 flex align-center -mb-1">
        {title}
      </h2>
      <p className="text-base text-gray-500 text-sm md:text-md lg:text-lg md:mt-2 lg:mx-0 ">
        {desc}
      </p>
    </div>
  </div>

  <div className="container mx-auto px-4 md:px-28 pt-4 select-none">
    {children}
  </div>
</div>

  );
}



export default Header;
