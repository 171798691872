import axios from "axios";
import { observable, computed, makeObservable, action } from "mobx";
import { configure } from "mobx";
import FuzzySet from "fuzzyset";
import Filter from "bad-words";
import config from "./config";

let filterBadWords = new Filter();
let baseURL = config.baseURL;

configure({ enforceActions: "never" });

let api = axios.create({ baseURL });

class appStore {
  api = api;

  // App
  @observable baseURL = baseURL;
  @observable redirect = ``;
  @observable editor;
  @observable editorIsLoading = true;

  // User Profile
  @observable profile = {};
  @observable isLoggedIn = false;
  @observable loginLoading = false;

  // Tasks
  @observable landingPageUrl = config.landingPageUrl;
  @observable TASKS = [];
  @observable currentTask = [];
  @observable task = null;
  @observable taskLoading = false;
  @observable taskOutputLoading = false;
  @observable taskError = null;

  @observable aichain = null;
  @observable aichainLoading = false;
  @observable aichainError = null;

  @observable plans = [];
  @observable plan = null;
  @observable planLoading = false;
  @observable planError = null;

  editor;

  // Referral
  @observable referral = "";

  constructor() {
    // makeObservable enables MobX observable annotations (@observable,  @computed and @action)
    makeObservable(this);

    // Set profile and token from local storage
    this.setProfileAndToken();

    // Check for credits or authenticated
    this.checkForCreditsOrAuthenticated();
  }

  checkForCreditsOrAuthenticated = () => {
    // Check credits every time, and log out people who aren't authenticated
    // If they are authenticated, but have no credits, send them to the no-credits page
    // If they are authenticated, and have credits, let them continue
    this.api.interceptors.response.use(
      (response) => {
        this.updateCredits(response);
        return response;
      },
      (error) => {
        console.error(error);
        console.log(`error.response.statusText`, error.response.statusText);
        if (
          error.response &&
          error.response.statusText === "Token Authentication Failed"
        ) {
          this.handleLogout();
        }
        if (
          error.response &&
          error.response.statusText === "No Credit Remaining"
        ) {
          this.goToProfilePage();
        }
        return Promise.reject(error);
      }
    );
  };

  goToProfilePage = () => {
    // set the browser url to the no-credits page
    window.location.pathname = "/my-profile";
  };

  setProfileAndToken = async () => {
    try {
      this.setSeferralTrackingCode();
      const profile = localStorage.getItem("profile");
      const token = localStorage.getItem("token");
      if (profile && token) {
        this.api.defaults.headers.common["x-access-token"] = token;
        this.profile = JSON.parse(profile);
        this.isLoggedIn = true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  setSeferralTrackingCode = async () => {
    let referral = new URLSearchParams(window.location.search).get("referral");
    if (referral) {
      this.setReferral(referral);
    } else {
      this.initReferral();
    }
  };

  setReferral = async (referral) => {
    this.referral = referral;
    localStorage.setItem("referral", JSON.stringify(referral));
  };

  initReferral = async () => {
    const referral = localStorage.getItem("referral");
    this.referral = referral;
  };

  loginWithDataTokenAndProfile = async (data) => {
    console.log("Profile info: ", data.profile);
    this.setToken(data.token);
    this.setProfile(data.profile);
    this.isLoggedIn = true;
  };

  refreshTokenAndProfile = async () => {
    try {
      let data = await this.api
        .post("/user/refresh/profile")
        .then(({ data }) => data);
      if (data) {
        this.setProfile(data.profile);
      }
    } catch (err) {
      console.log(err);
      this.handleLogout();
    }
  };

  setToken = async (token) => {
    this.api.defaults.headers.common["x-access-token"] = token;
    localStorage.setItem("token", token);
  };

  @action
  setProfile = async (profile) => {
    this.profile = profile;
    localStorage.setItem("profile", JSON.stringify(profile));
  };

  setTasks = async (tasks) => {
    this.TASKS = tasks;
  };

  handleLogout = () => {
    this.isLoggedIn = false;
    this.profile = {};
    this.api.defaults.headers.common["x-access-token"] = "";
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    console.log("Logged out");
  };

  @observable tasksKeyword = "";
  onChangeTasksKeyword = (e) => {
    this.tasksKeyword = e.target.value;
  };

  // get tasks based on key word
  @computed get tasks() {
    // let tasks = TASKS.filter(task => task.title.toLowerCase().includes(this.tasksKeyword.toLowerCase()))
    const FuzzySearch = FuzzySet([...this.TASKS.map((task) => task.title)]);
    let fuzzyTasks = FuzzySearch.get(this.tasksKeyword, 0.5);
    if (fuzzyTasks && fuzzyTasks.length) {
      let fuzzySummary = fuzzyTasks.map((fuzzyTask) => fuzzyTask[1]);
      if (fuzzySummary && fuzzySummary.length) {
        return this.TASKS.filter((task) => fuzzySummary.includes(task.title));
      }
    }
    // console.log('TASKS in get tasks', TASKS)
    return this.TASKS;
  }

  getTaskByTitle = (title) => {
    return this.TASKS.find((task) => task.title === title);
  };

  getTaskByUrl = (url) => {
    console.log(
      "task by URL id",
      this.TASKS.find((task) => task.to === url)
    );
    console.log(
      "task by URL type by id",
      typeof this.TASKS.find((task) => task.to === url)
    );
    return this.TASKS.find((task) => task.to === url);
  };

  getTasks = async () => {
    console.log("getTasks Tasks");
    try {
      const response = await this.api.get("/tasks", {});
      this.TASKS = response.data;
      console.log("Array of Tasks", this.TASKS);
      return this.TASKS;
    } catch (error) {
      console.log(error);
    }
  };

  getTaskById = async (taskId) => {
    console.log("store: getTaskById:", taskId);

    const response = await this.api.get(`/tasks/${taskId}`);
    return response.data;
  };

  uploadImageVideo = async (formData, onProgress) => {
    const response = await this.api.post("/tasks/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted); // Use the callback here
      },
    });

    console.log("uploadImageVideo response:", response);

    if (response.status === 200) {
      const taskId = response.data.taskId;
      await this.updateCurrentTask(taskId);
    }

    return response;
  };

  updateCurrentTask = async (taskId) => {
    console.log("updateCurrentTask: ", taskId);
    const task = await this.getTaskById(taskId);
    this.currentTask = task;
    return task;
  };

  resetValues = async (userId) => {
    console.log("resetTasks", userId);

    const response = await this.api.post("/tasks/resetTasks", { userId });
    console.log("response", response);
    if (response) {
      alert("Tasks have been reset.");
    }

    return;
  };

  @observable error = "";
  checkPrompt = ({ value, attr }) => {
    if (filterBadWords.isProfane(value)) {
      // eslint-disable-next-line no-throw-literal
      throw {
        success: false,
        attr,
        value: value.replace(/^\s+|\s+$/g, ""),
        message: "Unsafe content detected, please try different language",
      };
    }
    if (value) {
      return {
        success: true,
        attr,
        value: value.replace(/^\s+|\s+$/g, ""),
      };
    }
  };

  checkOutput = (output) => {
    if (output) {
      return output.replace(/^\s+|\s+$/g, "");
    }
    return "";
  };

  updateCredits = async (data) => {
    try {
      if (data.hasOwnProperty("data")) {
        if (data.data.hasOwnProperty("credits")) {
          this.profile.credits = data.data.credits;
        }
        if (data.data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.data.creditsUsed;
        }
      } else {
        if (data.hasOwnProperty("credits")) {
          this.profile.credits = data.credits;
        }
        if (data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.creditsUsed;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  @observable copyToClipboardText = ``;
  copyToClipboard = (output) => {
    if (output instanceof Array) {
      output = output.join("\n");
    }
    if (!navigator.clipboard) {
      let textarea = document.getElementById("copy-textarea");
      this.copyToClipboardText = `${output}`;
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      return;
    }
    navigator.clipboard.writeText(output).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  @observable sendToGoogleDocText = ``;
  sendToGoogleDoc = async (output, doc) => {
    if (output instanceof Array) {
      output = output.join("\n");
    }

    // Get the profile object from local storage
    const profile = JSON.parse(localStorage.getItem("profile"));
    let docId = "";

    if (doc == "Target Market") {
      docId = profile.targetMarketDocId;
      console.log(docId);
    }

    if (doc == "Transformation Offer") {
      docId = profile.offerDocId;
      console.log("Transformation Offer", docId);
    }

    if (doc == "Funnel Blueprint") {
      docId = profile.funnelDocId;
      console.log("Funnel Blueprint:", docId);
    }

    if (doc == "Sales Process") {
      docId = profile.salesDocId;
      console.log("Sales Process", docId);
    }

    let folderId = profile.folderId;

    try {
      // Replace '/api/send-to-doc' with the actual API endpoint.
      const response = await this.api.post("/ai/sendtodoc", {
        output,
        folderId,
        docId,
      });

      if (response.status === 200) {
        console.log("Async: Sending to google doc was successful!");
        alert("Async: Sending to google doc was successful!");
      } else {
        console.error("Async: Sending to google doc failed: ", response.status);
      }
    } catch (error) {
      console.error("Async: Sending to google doc failed: ", error);
    }
  };

  @observable feedback = ``;
  reportToFeedback = (output) => {
    this.redirect = "/my-profile/feedback";
    this.feedback = `${output}`;
    setTimeout(() => {
      this.redirect = "";
    }, 50);
  };

  @action
  async fetchPlan(planId) {
    this.planLoading = true;
    try {
      const response = await this.api.get(`/plans/${planId}`);
      this.plan = response.data;
    } catch (error) {
      console.error("Error fetching plan:", error);
      this.planError = error.message;
    } finally {
      this.planLoading = false;
    }
  }

  @action
  async fetchTask(taskId) {
    this.planLoading = true;
    try {
      const response = await this.api.get(`/tasks/${taskId}`);
      this.task = response.data;
    } catch (error) {
      console.error("Error fetching task:", error);
      this.taskError = error.message;
    } finally {
      this.taskLoading = false;
    }
  }

  @action
  async fetchAiChain(aiChainId) {
    this.planLoading = true;
    try {
      const response = await this.api.get(`/aichains/${aiChainId}`);
      this.aichain = response.data;
    } catch (error) {
      console.error("Error fetching aichain:", error);
      this.aichainError = error.message;
    } finally {
      this.aichainLoading = false;
    }
  }

  async updateTaskUiInputs(updatedUiInputs) {
    this.task.ui.uiInputs = updatedUiInputs;
  }

  async updateTaskUiOutputs(updatedUiOutputs) {
    this.task.ui.uiOutputs = updatedUiOutputs;
  }

  async updateTaskAiResponse(updatedAiResponse) {
    this.task.aichain.aiOutput.airesponse = updatedAiResponse;
  }

  async updateTask() {
    try {
      const response = await this.api.put(`/tasks/${this.task._id}`, this.task);
      console.log("updateTaskUiInputs response:", response);
    } catch (error) {
      console.error("Error updating task:", error);
      this.taskError = error.message;
    }
  }

  @action
  async saveTaskAndRunAutomation() {
    try {
      this.taskLoading = true;
      console.log("saveTaskAndRunAutomation this.task");
      const response = await this.api.post(
        `/ai/savetaskandautomate/${this.task._id}`,
        this.task
      );
      console.log("saveTaskAndRunAutomation response:", response);
      this.task = response.data;
    } catch (error) {
      console.error("Error updating task:", error);
      this.taskError = error.message;
    } finally {
      this.taskLoading = false;
    }
  }

  @action
  async saveTaskAndUpdateAiOutput() {
    try {
      this.taskOutputLoading = true;
      const response = await this.api.post(
        `/ai/savetaskandupdate/${this.task._id}`,
        this.task
      );
      console.log("saveTaskAndRunAutomation response:", response);
      this.task = response.data;
    } catch (error) {
      console.error("Error updating task:", error);
      this.taskError = error.message;
    } finally {
      this.taskOutputLoading = false;
    }
  }

  @action
  async fetchPlans() {
    this.planLoading = true;
    try {
      const response = await this.api.get(`/plans`);
      this.plans = response.data;
    } catch (error) {
      console.error("Error fetching plan:", error);
      this.planError = error.message;
    } finally {
      this.planLoading = false;
    }
  }
}

export default appStore;
