import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { CheckIcon, UserIcon, LockClosedIcon } from "@heroicons/react/outline";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer, inject } from "mobx-react";
import { RotatingLines } from "react-loader-spinner";

const LoginPage = inject("store")(
  observer(({ store }) => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      // Initialize the loading state as false
      setLoading(false);
    }, []);

    const onChange = (val, attr) => {
      if (attr === "email") {
        setEmail(val);
      } else if (attr === "password") {
        setPassword(val);
      } else if (attr === "fname") {
        setFname(val);
      } else if (attr === "lname") {
        setLname(val);
      }
      setErrorMessage("");
    };

    const onLogin = async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        let data = await store.api.post("/auth/signin", {
          email: email,
          password: password,
        });
        data = data.data; // Access data property of the response

        if (data.token && data.profile) {
          store.loginWithDataTokenAndProfile(data);

          //
          if (data.profile.defaultPlanId) {
            console.log(`Login success. Redirecting to plan ${data.profile.defaultPlanId}`);
            history.push(`/plan/${data.profile.defaultPlanId}`);
          }
        }
      } catch (err) {
        console.log(err);
        console.log(err?.response?.data?.message);
        if (err?.response?.data?.message) {
          setErrorMessage(err?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Helmet>
          <title>{`Login - T.R.E.`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-6">
            <Logo />
            <div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">
              T.R.E.
            </div>
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300">
              {/* <NavLink to="/login"  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/login"  ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/login" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/login"  ? "white" : "gray-100"} cursor-pointer`} >
            <div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/login" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/login" ? "green" : "gray"}`}>
            <CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/login" ? "green-600" : "gray-400"}`} aria-hidden="true" />
            </div>
             Login
      </NavLink>
      */}
              {/* <NavLink to="/signup"  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/signup"  ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/signup" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/signup"  ? "white" : "gray-100"} cursor-pointer`} >
            <div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/signup" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/signup" ? "green" : "gray"}`}>
            <CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/signup" ? "green-600" : "gray-400"}`} aria-hidden="true" />
            </div>
             Signup
      </NavLink> */}
            </div>
            <div className="px-4 py-4 md:px-12 md:py-12">
              <Switch>
                <Route path="/login">
                  <Logon
                    email={email}
                    password={password}
                    onChange={onChange}
                    onLogin={onLogin}
                    loading={loading}
                  />
                </Route>

                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
              {errorMessage ? (
                <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                  {errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  })
);

const Logon = observer(({ email, password, onChange, onLogin, loading }) => {
  return (
    <>
      <form onSubmit={onLogin}>
        {/* <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${(email && password) ? "green" : "gray"}-300  ${(email && password) ? "bg-green-300" : "bg-gray-300"} `}>
			  		<LockClosedIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${(email && password) ? "green-700" : "gray-500"}`} aria-hidden="true" />
                </div> */}
        <div className=" text-center ">
          {/* <div className="text-3xl font-medium text-gray-900">
                    	Log in
                    </div> */}
          {/* <p className="text-lg text-gray-500">
					Login to your account
					</p> */}
          <div className="flex flex-col flex-1">
            <label className="text-gray-400 text-sm block inline-block text-left">
              Email Address
            </label>
            <input
              value={email}
              onChange={(e) => onChange(e.target.value, "email")}
              focus="true"
              type="email"
              className="rounded-md text-lg px-4 py-2  border border-gray-300 "
              placeholder="john@smith.com"
            />
          </div>
          <div className="flex flex-col flex-1">
            <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
              Password
            </label>
            <input
              value={password}
              onChange={(e) => onChange(e.target.value, "password")}
              type="password"
              className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
              placeholder="*******"
            />
          </div>

          <div className="flex flex-col">
            <LoginButton onLogin={onLogin} loading={loading} />

            {/* <div onClick={signUp} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
						signUp Google
						</div>
						 */}
            <a
              href={`https://www.openaitemplate.ai/contact`}
              className="mt-4 text-gray-400 text-sm"
            >
              Forgot your password?
            </a>
          </div>
        </div>
      </form>
    </>
  );
});

const Logo = () => (
  <img src="/tre.png" alt="Logo" className="w-20 h-20 inline-block" />
);

const LoginButton = ({ onLogin, loading }) => {
  return (
    <button
      type="submit"
      className="h-16 hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-flex items-center justify-center"
      onClick={onLogin}
      disabled={loading}
    >
      {loading ? (
        <span>
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="25"
            visible={true}
          />
        </span>
      ) : (
        <span>Log in</span>
      )}
    </button>
  );
};

export default LoginPage;
