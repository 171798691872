import React, { useState, useEffect } from "react";
import { PencilIcon, InformationCircleIcon } from "@heroicons/react/outline";
import EntryInput from "../Components/EntryInput";

import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Body from "../Components/Body";
import { withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import styled from "styled-components";
import Filter from "bad-words";

let filterBadWords = new Filter();

const Task = inject("store")(
  observer((props) => {

    const [task, setTask] = useState({});
    const [prompts, setPrompts] = useState([]);
    const [currentPrompt, setCurrentPrompt] = useState(0);
    const [currentOption, setCurrentOption] = useState("Start Using");
    const [error, setError] = useState("");
    const [output, setOutput] = useState("");
    const [outputs, setOutputs] = useState([]);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(Date.now() + 1000);
    const [countdown, setCountdown] = useState([]);
    const [clearExampleTimeout, setClearExampleTimeout] = useState([]);
    const [language, setLanguage] = useState("");

    useEffect(() => {
      const initializeTask = () => {
        const taskData = props.store.getTaskByUrl(props.location.pathname);
        if (!taskData) {
          window.location.href = "/";
        } else {
          setTask(taskData);
          setPrompts([...taskData.prompts]);
        }
      };
      initializeTask();
    }, [props.location.pathname, props.store]);

    const handleCurrentPrompt = (val) => {
      setCurrentPrompt(val);
    };

    const isGenerateButtonDisabled = () => {
      if (loading) {
        return true;
      }
      return false;
    };

    const isMinLength = () => {
      if (!prompts[currentPrompt].min) {
        return false;
      }
      if (!prompts[currentPrompt].type === "number") {
        return false;
      }
      return false;
    };

    const checkMinimumPrompts = () => {
      let shouldReturn = false;
      prompts[currentPrompt].prompts.forEach((prompt, promptIndex) => {
        if (prompt.min) {
          if (prompt.value.length < prompt.min) {
            shouldReturn = true;
            prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
          }
        }
      });
      return shouldReturn;
    };

    const clearExampleTimeouts = () => {
      clearExampleTimeout.forEach((item, index) => {
        clearTimeout(item);
      });
    };

    const onStartUsing = async () => {
      setLoading(false);
      setError("");
      clearExampleTimeouts();
      setCurrentOption("Start Using");
    };

    const onExample = async () => {
      setLoading(true);
      setError("");
      setOutput("");
      setOutputs([]);
      setCode("");

      setCurrentOption("Example");

      let totalLength = 0;

      clearExampleTimeouts();

      prompts[currentPrompt].prompts.forEach((prompt, promptIndex) => {
        prompts[currentPrompt].prompts[promptIndex].value = "";
      });

      prompts[currentPrompt].prompts.forEach((prompt, promptIndex) => {
        for (
          let timeoutIndex = 0;
          timeoutIndex < prompt.example.length;
          timeoutIndex++
        ) {
          totalLength++;
          setClearExampleTimeout((prev) => [
            ...prev,
            setTimeout(() => {
              prompts[currentPrompt].prompts[promptIndex].value +=
                prompt.example[timeoutIndex];
            }, 7 * totalLength),
          ]);
        }
      });

      totalLength++;

      if (prompts[currentPrompt].example.output) {
        setClearExampleTimeout((prev) => [
          ...prev,
          setTimeout(() => {
            setOutput(prompts[currentPrompt].example.output);
            totalLength++;
            setClearExampleTimeout((prev) => [
              ...prev,
              setTimeout(() => {
                setLoading(false);
                setCurrentOption("Start Using");
                prompts[currentPrompt].prompts[0].value += " ";
              }, 7 * totalLength + prompts[currentPrompt].example.output.length * 7 + 500),
            ]);
          }, 7 * totalLength + 500),
        ]);
      }

      if (prompts[currentPrompt].example.code) {
        totalLength++;
        setClearExampleTimeout((prev) => [
          ...prev,
          setTimeout(() => {
            setCode(`${prompts[currentPrompt].example.code}`);
          }, 7 * totalLength + 500),
        ]);
      }

      if (prompts[currentPrompt].example.outputs) {
        setClearExampleTimeout((prev) => [
          ...prev,
          setTimeout(() => {
            setOutputs(prompts[currentPrompt].example.outputs);
            totalLength++;
            setClearExampleTimeout((prev) => [
              ...prev,
              setTimeout(() => {
                setLoading(false);
                setCurrentOption("Start Using");
              }, 7 * totalLength + 500),
            ]);
          }, 7 * totalLength + 500),
        ]);
      }
    };

    const sanitizeAllPrompts = () => {
      prompts[currentPrompt].prompts.forEach((prompt) => {
        if (!prompt.value) {
          return false;
        }
        if (prompt.type === "number") {
          return false;
        }

        prompt.value = prompt.value.trim();

        if (filterBadWords.isProfane(prompt.value)) {
          prompt.error = "Unsafe content, please try different language";
          throw Error("Unsafe content");
        }
      });
    };

    const contentFilterFlagged = async (response) => {
      setError(response.message);
      setDate(Date.now() + 5000);
      countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      setLoading(false);
    };

    const checkOutput = (output) => {
      if (output) {
        output = output.replace(/^\s+|\s+$/g, "");
        // output = output.replace(/\s{2,}/g, ' ')
      }
      return output;
    };

    const onGenerateClick = async () => {
      try {
        setError("");
        setOutput("");
        setCode("");
        setOutputs([]);
        setLoading(true);

        let checkMinimumPromptsValue = checkMinimumPrompts();
        if (checkMinimumPromptsValue) {
          setLoading(false);
          return false;
        }

        let postObj = {};

        prompts[currentPrompt].prompts.forEach((prompt) => {
          postObj[prompt.attr] = prompt.value;
        });

        postObj.currentPrompt = prompts[currentPrompt].title;
        if (prompts[currentPrompt].n) {
          postObj.n = prompts[currentPrompt].n;
        }

        let response = await props.store.api.post(task.api, postObj);

        if (!response.data.success) {
          contentFilterFlagged(response.data);
          return false;
        }

        if (response.data.output) {
          setOutput(checkOutput(response.data.output));
        }

        if (response.data.code) {
          setCode(response.data.code);
        }

        if (response.data.outputs) {
          setOutputs(response.data.outputs);
        }

        setDate(Date.now() + 10000);
        countdown.forEach((countdown) => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        countdown.forEach((countdown) => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        setLoading(false);
      }
    };

    return (
      <>
        <Helmet>
          <title>{`${task.title} Task`}</title>
        </Helmet>
        <Header
          title={task.title}
          desc={task.desc}
          Icon={task.Icon}
          fromColor={task.fromColor}
          category={task.category}
          options={[
            {
              title: "Start Using",
              Icon: PencilIcon,
              color: props.store.profile.credits ? "green" : "red",
              onClick: onStartUsing,
            },
            {
              title: "Example",
              color: "yellow",
              Icon: InformationCircleIcon,
              onClick: onExample,
            },
          ]}
          currentOption={currentOption}
        />
        <Body className="flex flex-1">
          <Messages className="flex flex-col flex-1">
            <AllMessages className="flex flex-1 border border-red-400 p">
              AllMessages
            </AllMessages>
            <MessageInput className="pb-8">
              <EntryInput
                Icon={PencilIcon}
                prompt={{
                  name: "",
                  value: "",
                  placeholder: "Write a message...",
                }}
              />
            </MessageInput>
          </Messages>
        </Body>
      </>
    );
  })
);

const AllMessages = styled.div``;

const MessageInput = styled.div``;

const Messages = styled.div``;

export default withRouter(Task);
