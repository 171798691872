
import React from "react";
import { Link } from "react-router-dom";


export const TaskItem = ({
    task,
    fromColor,
    isCompleted,
    isHuman,
    manualTaskInProgress,
  }) => (
    <div>
    {task.executedBy == 'ai' && (
    <Link to={`/task/${task._id}` || "/"} className="flex relative">
      <div className="bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:border-gray-500 hover:text-black">
        <div className="p-4 flex items-start">


          {/* AI States */}
          {task.status == 'complete' && task.executedBy == 'ai' && (
            <img
              src="/completed-tre.png"
              alt="Checkmark"
              className="mr-4 h-20 w-20"
            />
          )}
          {task.status == 'in progress' && task.executedBy == 'ai' && (
            <img
              src="/inprogress-tre.png"
              alt="Not Checkmark"
              className="mr-4 h-20 w-20"
            />
          )}
          {task.status == 'to do' && task.executedBy == 'ai' && (
            <img
              src="/not-completed-tre.png"
              alt="Not Checkmark"
              className="mr-4 h-20 w-20"
            />
          )}
  
          {/* Human States */}
          {task.status == 'in progress' && task.executedBy == 'human' && (
            <img
              src="/inprogress-human.png"
              alt="Checkmark"
              className="mr-4 h-20 w-20"
            />
          )}
          {task.status == 'to do' && task.executedBy == 'human' && (
              <img
                src="/not-completed-human.png"
                alt="Not Checkmark"
                className="mr-4 h-20 w-20"
              />
            )}
          {task.status == 'complete' && task.executedBy == 'human' && (
              <img
                src="/completed-human.png"
                alt="Checkmark"
                className="mr-4 h-20 w-20"
              />
            )}
  
          <div className="flex flex-col">
            <div
              className={`uppercase tracking-wide text-sm text-${
                fromColor ? fromColor : "green-500"
              } font-semibold leading-none`}
            >
              {task.category || "New"}
            </div>
            <div
              href="#"
              className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
            >
              {task.title}
            </div>
            <p className="mt-1 pr-1 text-sm">{task.description}</p>
          </div>
        </div>
      </div>
    </Link>
    )}
    {task.executedBy == 'ai-report' && (
          <Link to={`/report/${task._id}` || "/"} className="flex relative">
          <div className="bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:border-gray-500 hover:text-black">
            <div className="p-4 flex items-start">
    
    
              {/* AI States */}
              {task.status == 'complete'  && (
                <img
                  src="/completed-tre.png"
                  alt="Checkmark"
                  className="mr-4 h-20 w-20"
                />
              )}
              {task.status == 'in progress' && (
                <img
                  src="/inprogress-tre.png"
                  alt="Not Checkmark"
                  className="mr-4 h-20 w-20"
                />
              )}
              {task.status == 'to do' && (
                <img
                  src="/not-completed-tre.png"
                  alt="Not Checkmark"
                  className="mr-4 h-20 w-20"
                />
              )}
              <div className="flex flex-col">
                <div
                  className={`uppercase tracking-wide text-sm text-${
                    fromColor ? fromColor : "green-500"
                  } font-semibold leading-none`}
                >
                  {task.category || "New"}
                </div>
                <div
                  href="#"
                  className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
                >
                  {task.title}
                </div>
                <p className="mt-1 pr-1 text-sm">{task.description}</p>
              </div>
            </div>
          </div>
        </Link>

    )}
    </div>

  );