import React from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import taskPermissions from './taskpermissions.json';

const TaskListPage = (props) => {
  const permissions = computed(() => {
    return props.store.tasks.filter((task) =>
      task.permissions.some((r) => props.store.profile.permissions.includes(r))
    );
  });

  const groupedPermissions = computed(() => {
    const permissionsList = permissions;
    const grouped = permissionsList.reduce((groupedPermissions, permission) => {
      const group = permission.category;
      if (!groupedPermissions[group]) {
        groupedPermissions[group] = [];
      }
      groupedPermissions[group].push(permission);
      return groupedPermissions;
    }, {});
    return grouped;
  });

  const handleRowClick = (permission) => {
    // Navigate to the permission's URL when the row is clicked
    if (permission.to) {
      props.history.push(permission.to);
    }
  };

  const filteredGroups = taskPermissions.groups.filter((group) => group.visible);
  const groupNames = taskPermissions.groups.map((group) => group.name);

  return (
    <>
      <Helmet>
        <title>{`Tre`}</title>
      </Helmet>
      <MainBody>
        {groupNames.map((groupName) => {
          const groupPermissions = groupedPermissions[groupName];
          const groupConfig = filteredGroups.find((group) => group.name === groupName);
          if (!groupConfig) {
            return null;
          }
          return (
            <React.Fragment key={groupName}>
              <TableHeader>{groupName}</TableHeader>
              <Table>
                <tbody>
                  {groupPermissions.map((permission, index) => {
                    const isCompleted = permission.isCompleted;
                    const isHighlighted = groupConfig.highlighted.includes(permission.title);
                    if (groupConfig.hideCompleted && isCompleted) {
                      return null;
                    }
                    if (groupConfig.hideNotHighlighted && !isHighlighted) {
                      return null;
                    }
                    return (
                      <TableRow
                        key={index}
                        isHighlighted={isHighlighted}
                        onClick={() => handleRowClick(permission)}
                      >
                        <TableCell>
                          <Checkbox
                            type="checkbox"
                            checked={permission.isCompleted}
                            onClick={(event) => event.stopPropagation()}
                          />
                          <TaskName completed={isCompleted}>{permission.title}</TaskName>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          );
        })}
      </MainBody>
    </>
  );
};

const MainBody = styled.div`
  padding: 1rem;
`;

const TableHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: white;
  }
  ${(props) => props.isHighlighted && 'background-color: #F6E05E;'}
  cursor: pointer;
`;

const TableCell = styled.td`
  padding: 0.5rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: 2px solid #555;
  &:checked {
    background-color: green;
    border-color: green;
  }
`;

const TaskName = styled.span`
  text-decoration: ${(props) => (props.completed ? 'line-through' : 'none')};
`;

export default inject('store')(observer(TaskListPage));
